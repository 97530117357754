
import {
    required,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators';

export const FormRouteVisita = () => {
    return {
      formRouteVisita: {
        VisitDocDs:{
          required,
          minLength: minLength(4),
          maxLength: maxLength(500)
        },
      }
    }
}