<template>
    <CRow style="margin-top:20px;">
        <CCol sm="12">
            <CCard bodyWrapper>
                <CRow>
                    <CCol sm="12" class="mb-3" v-if="indexInformation === 1">
                        <vue-dropzone 
                            :aria-disabled="idIndentification" 
                            ref="myVueDropzone" 
                            id="dropzone" 
                            :options="dropzoneOptions" 
                            :useCustomSlot="true"
                            v-on:vdropzone-file-added="sendingEvent"
                            v-on:vdropzone-files-added="sendingEvent"
                            v-on:vdropzone-error="DropzoneError"
                        > 
                            <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                                <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                            </div>
                        </vue-dropzone>
                    </CCol>
                    <CCol sm="11">
                        <CInput
                            :label="$t('label.description')"
                            horizontal
                            addLabelClasses="required text-right"
                            :placeholder="$t('label.documentDescription')"
                            :disabled="idIndentification"
                            v-uppercase
                            v-model="$v.formRouteVisita.VisitDocDs.$model"
                            :addInputClasses="{ 'is-invalid': $v.formRouteVisita.VisitDocDs.$error }"
                            @blur="$v.formRouteVisita.VisitDocDs.$touch()"
                        >
                            <template #invalid-feedback>
                                <div v-if="$v.formRouteVisita.VisitDocDs.$error">
                                    <div class='text-danger' v-if="!$v.formRouteVisita.VisitDocDs.required">{{$t('label.required')}}</div>
                                    <div class='text-danger' v-if="!$v.formRouteVisita.VisitDocDs.minLength">{{$t('label.required')}} {{$t('label.min')}} {{$v.formRouteVisita.VisitDocDs.$params.minLength.min}} {{$t('label.chars')}}</div>
                                    <div class='text-danger' v-if="!$v.formRouteVisita.VisitDocDs.maxLength">{{$t('label.required')}} {{$t('label.max')}} {{$v.formRouteVisita.VisitDocDs.$params.maxLength.min}} {{$t('label.chars')}}</div>
                                </div>
                            </template>
                        </CInput>
                    </CCol>
                    <CCol sm="1"  class="d-flex align-items-start justify-content-end">
                        <CButton 
                            color="add"
                            v-c-tooltip="{
                                content: $t('label.add'),
                                placement: 'top-end'
                            }"
                            @click="sendDateRoute()"  
                            :disabled="loading || idIndentification"
                        >
                            <CIcon name="cil-plus"/>
                        </CButton>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
     class="align-center-row-datatable"
                :items="formatedItems"
                :fields="fields"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                sorter
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                :items-per-page="4"
                pagination
                column-filter
                hover
            >
                <template #VisitDocId="{ index}">
                    <td class="text-center">
                        {{index+1}}
                    </td>
                </template>
                <template #status="{item}">
                    <td class="center-cell text-center">
                        <CBadge v-if="item.status" color="success">
                            {{item.Status}}
                        </CBadge>
                        <CBadge v-if="!item.status" color="danger">
                            {{item.Status}}
                        </CBadge>
                    </td>
                </template>
                <template #acciones="{item}">
                    <td class="center-cell text-center">
                        <CButton
                            color="watch"
                            size="sm" 
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.toView')+' '+$t('label.document'),
                                placement: 'top-end'
                            }"
                            
                            target="_blank"
                            :href="routeDoc(item)"
                        >
                            <CIcon name="eye"/>
                        </CButton>
                        <CButton
                            color="wipe"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.removeDocument'),
                                placement: 'top-end'
                            }"
                            v-if="item.FgActVisitDoc"
                            @click="eliminarArchivo(item)"
                        >
                            <CIcon name="cil-trash" />
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { DateFormater } from '@/_helpers/funciones';
    import { FormRouteVisita } from '@/_validations/visitas/generalInformation';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    let user = JSON.parse(localStorage.getItem('user'));

    function fields(){
        return [
            { key: 'VisitDocId', label: '#',_style: 'width:1%', },
            { key: 'VisitDocDs',label: this.$t('label.document'), _style:'min-width:100px' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-uppercase' },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center"},
            { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:1%', 
                sorter: false, 
                filter: false
            },
        ];
    }
    //data
    function data() {
        return {
            formRouteVisita:{
                VisitDocId:0,
                VisitDocDs:'',
                RouteVisitDoc:"",
                FgActVisitDoc:true,
            },
            listDocuments:[],
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_BASE}files/`,
                autoProcessQueue: false,
                maxFiles: 1,
                addRemoveLinks: true,
                dictRemoveFile: `${this.$t('label.delete')}`,
                dictCancelUpload: `${this.$t('label.cancelUpload')}`,
                maxfilesexceeded (files) {
                    this.removeAllFiles();
                    this.addFile(files);
                },
                acceptedFiles: '.pdf, .doc, .docx, .xlsx',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`
                }
            },
        }
    }
    //methods
    function routeDoc(item){
        return `${this.$store.getters["connection/getBase"]}${item.RouteVisitDoc}`;
    }
    function sendingEvent(file){
        if(this.VisitId === ''){
            this.$refs.myVueDropzone.removeFile(file);
        }
    }
    function DropzoneError(file) {
        this.$refs.myVueDropzone.removeFile(file);
    }

    function resetform() {
        this.formRouteVisita.VisitDocId=0;
        this.formRouteVisita.VisitDocDs='';
        this.formRouteVisita.RouteVisitDoc="";
        this.formRouteVisita.FgActVisitDoc=true;
        this.$refs.myVueDropzone.removeAllFiles();
        this.$nextTick(() => {
            this.$v.formRouteVisita.$reset();
        });
    }
    function loadData() {
        if(this.VisitId !== ''){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar("GET", "VisitDoc-list", {
                VisitId: this.VisitId,
            }).then(response => {
                this.listDocuments = response.data.data;
            }).catch( err => {
                this.$store.commit('visitas/messageMutation', err);
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }).then(() => {
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            });
        }
    }
    function mounted(){
        if(this.VisitId !== ''){
            this.loadData();
        }
    }
    async function sendDateRoute(){
        const files = this.$refs.myVueDropzone.getQueuedFiles();
        if(files.length === 0 || this.VisitId === ''){
            this.$notify({
                group: 'container',
                type: 'error',
                ignoreDuplicates:false,
                title: 'ERROR',
                text: `${this.$t('label.documentQuestion')}`,
            });
            return;
        }
        this.$v.formRouteVisita.$touch();
        if (this.$v.formRouteVisita.$pending || this.$v.formRouteVisita.$error){ return };

        let { VisitDocId,VisitDocDs,RouteVisitDoc,FgActVisitDoc } = this.formRouteVisita;

        this.$swal.fire({
            text: `${this.$t('label.addFileQuestion')}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#42AA91',
            cancelButtonColor: '#E1373F',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    let typeForm = "POST";
                    let routeForm = "VisitDoc-insert";
                    const filesDoc = RouteVisitDoc === "" ? null : RouteVisitDoc;

                    const response = await this.$http.file("Visit-file",files[0],filesDoc);

                    const info = response.data.data;
                    const routerNew = info.files[0].path;

                    let VisitDocJson = {
                        VisitId : this.VisitId,
                        VisitDocDs: VisitDocDs,
                        RouteVisitDoc: routerNew
                    }

                    if(VisitDocId !== 0){
                        typeForm = "PUT";
                        routeForm = "VisitDoc-update";

                        VisitDocJson.VisitDocId = VisitDocId;
                        VisitDocJson.Status = FgActVisitDoc ? 1 : 0;
                    }

                    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar(typeForm, routeForm, VisitDocJson, { root: 'VisitDocJson' })
                    .then(response => {
                        let res = [...response.data.data];
                        this.$notify({
                            group: 'container',
                            text: res[0].Response,
                            type: "success"
                        });
                        this.loadData();
                        this.resetform();
                        this.$store.state.visitas.apiStateForm = ENUM.INIT;
                    }).catch((err) => {
                        this.$store.commit('visitas/messageMutation', err);
                        this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                    });
                })();
            }
        })
    }
    function eliminarArchivo(item){
        let { VisitDocId,VisitDocDs,RouteVisitDoc,FgActVisitDoc } = item;

        this.$swal.fire({
            text: `${this.$t('label.deleteQuestion')+' '} ${VisitDocDs}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#42AA91',
            cancelButtonColor: '#E1373F',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                let VisitDocJson = {
                    VisitDocId: VisitDocId,
                    VisitId : this.VisitId,
                    VisitDocDs: VisitDocDs,
                    RouteVisitDoc: RouteVisitDoc,
                    Status: !FgActVisitDoc ? 1 : 0,
                }

                this.$store.state.visitas.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar("PUT", "VisitDoc-update", VisitDocJson, { root: 'VisitDocJson' })
                .then(response => {
                    let res = [...response.data.data];
                    this.$notify({
                        group: 'container',
                        text: res[0].Response,
                        type: "success"
                    });
                    this.loadData();
                    this.$store.state.visitas.apiStateForm = ENUM.INIT;
                }).catch((err) => {
                    this.$store.commit('visitas/messageMutation', err);
                    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
                });
            }else {
                // this.$swal(this.$t('label.operationCancelled'));
            }
        })
    }
    //computed
    function idIndentification(){
        return this.VisitId == '';
    }
    function formatedItems() {
        return this.listDocuments.map((item,index) => Object.assign({}, item, {
            RowNumber: item.Nro,
            UltimoUsuario: item.TransaLogin,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            status: item.FgActVisitDoc,
            _classes: ( item.FgActVisitDoc ) ? '' : 'table-danger'
        }));
    }
    export default {
        data,
        props: ['loading','indexInformation'],
        mounted,
        mixins: [TipoGruaMixin],
        directives: UpperCase,
        validations(){ return FormRouteVisita() },
        components: {
            vueDropzone: vue2Dropzone
        },
        methods:{
            loadData,
            sendDateRoute,
            resetform,
            routeDoc,
            sendingEvent,
            DropzoneError,
            eliminarArchivo,
        },
        computed:{
            idIndentification,
            formatedItems,
            fields,
            ...mapState({
                ItineraryId: state => state.visitas.ItineraryId,
                VisitId: state => state.visitas.VisitId,
            })
        },
        watch:{
            indexInformation(newValue,OldValue){
                if(newValue === 1){
                    this.loadData();
                }
            }
        }
    }
</script>